import React from "react";
import LayoutDay from "../../../components/Chronology/LayoutDay";
import ContentLeft from "../../../components/Chronology/Modules/ContentLeft";
import ContentRight from "../../../components/Chronology/Modules/ContentRight";
import ModCCAATable from "../../../components/Chronology/Modules/ModCCAATable";
import ModText from "../../../components/Chronology/Modules/ModText";
import InlineLink from "../../../components/Chronology/Modules/InlineLink";
import ModDataEu from "../../../components/Chronology/Modules/ModDataEu";
import ModLethalityEu from "../../../components/Chronology/Modules/ModLethalityEu";
import ModDottedLine from "../../../components/Chronology/Modules/ModDottedLine";
import ModGraph from "../../../components/Chronology/Modules/ModGraph";

export const frontmatter = {
  title: "Día 92",
  week: "Semana 14",
  day: "13",
  month: "Jun",
  monthNumber: "06",
  date: "2020-06-13",
  path: "/cronologia/semana-14#dia-13-jun/",
};

const Day92 = (props) => {
  return (
    <LayoutDay frontmatter={frontmatter} {...props}>
      <ContentLeft>
        <ModCCAATable fecha={frontmatter.date} />
        <ModDottedLine />
        <ModDataEu fecha={frontmatter.date} />
        <ModLethalityEu fecha={frontmatter.date} numPaises={8} />
      </ContentLeft>

      <ContentRight>
        <ModText>
          En los últimos siete días se han diagnosticado en{" "}
          <strong>España</strong> 1.733 casos mediante pruebas PCR. En ese mismo
          periodo, 125 personas han requerido hospitalización (7,2% de los
          diagnósticos), de los que siete han sido ingresos en la UCI, y se han
          producido 27 fallecimientos.
        </ModText>
        <ModGraph name="12-06-es-letalidad" alt="letalidad a 7 dias" />
        <ModText>
          Infectólogos del{" "}
          <strong>
            Servicio de Microbiología Clínica y Enfermedades Infecciosas
          </strong>{" "}
          del Hospital General Universitario Gregorio Marañón han detectado que
          pacientes con VIH en tratamiento antirretroviral parecen tener menos
          COVID-19.
        </ModText>
        <ModText>
          Este hallazgo ha permitido el nacimiento de{" "}
          <InlineLink link="https://www.mscbs.gob.es/gabinete/notasPrensa.do?metodo=detalle&id=4965">
            EPICOS
          </InlineLink>
          , uno de los mayores ensayos clínicos del mundo promovido por el
          Ministerio de Sanidad, que busca un tratamiento preventivo para la
          infección por coronavirus en profesionales sanitarios.
        </ModText>
        <ModText>
          Hasta la fecha, ya son 67 hospitales de 14 comunidades autónomas los
          que forman parte de EPICOS, y países como Argentina, Ecuador y Uruguay
          también se han sumado al estudio.
        </ModText>
      </ContentRight>
    </LayoutDay>
  );
};
export default Day92;
